.title {
    font-size: 32px;
    font-weight: 600;
    color: var(--font-base-color);
    text-align: center;
    margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
    .title {
        font-size: 24px;
    }
}