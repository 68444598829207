.modal {
    display: flex;
    flex-direction: column;
    padding: 40px 20px 0;
    font-family: 'Rubik';
    min-height: min(calc(100vh - 50px), 845px);
    position: relative;

    &Title {
        font-size: 18px;
        line-height: 145%;
        text-align: center;
        margin-bottom: 24px;
        color: #ffffff;
        padding: 0 50px;
    }

    &ButtonClose {
        position: absolute;
        right: 40px;
        top: 37px;
    }

    &Subtitle {
        line-height: 120%;
        color: #8a929a;
        text-align: center;
        margin-bottom: 50px;
    }
}
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 37px;
}
.btn {
    padding: 10px 40px;
    background: #333333;
    border-radius: 12px;
    margin-right: 20px;
    font-weight: 300;
    line-height: 19px;
    text-transform: capitalize;
    font-family: 'Rubik';
    font-size: 16px;
    color: #fff;
    &:last-child {
        margin-right: 0;
    }
    transition: 0.3s;
}
.active {
    background: #2261f6;
    transition: 0.3s;
}
@media screen and (max-width: 576px) {
    .modal{
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.9);
    }
}
@media screen and (max-width: 576px) {
    .modal{
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.9);
    }
}