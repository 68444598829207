.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 0;
}

.container {
    width: 100%;
    max-width: calc(870px + (var(--container-padding) * 2));
    padding: 0 var(--container-padding);
    display: flex;
    flex-flow: column nowrap;
    color: var(--font-base-color);
}

.title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 35px;
    text-align: center;
}

.block {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    margin-bottom: 24px;

    span {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
    }

    p {
        font-size: 14px;
    }

    ul {
        padding-left: 20px;
    }
}

.pdfDownload {
    display: flex;
    gap: 22px;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 50px;
    justify-content: center;
}