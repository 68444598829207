.wrapper{
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}
.container {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 32px;
}

.list {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
}

@media screen and (max-width: 576px) {
    .list {
        gap: 28px;
    }
}
