.svg {
    path {
        fill: #000;
    }
}

.wrapper {
    width: 265px;
    height: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: 100%;
        height: 100%;
    }
}

.smallSize {
    width: 27px;
    height: 13px;
}