.wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 -20px;
    flex: 1;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
}
.actions {
    display: flex;
    gap: 16px;
    padding: 24px 20px;
    margin-top: auto;
    background: #141414;
    width: 100%;

    button {
        text-transform: none;
    }
}
