.block {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: #282b31;
    border: 1px solid #6b6b6b;
    border-radius: 8px;
    gap: 16px;
    flex-grow: 1;
    padding-top: 28px;
    padding-bottom: 24px;
    box-sizing: border-box;
    div {
        background: var(--primary-color);
        border-radius: 50%;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 30px;
        height: 30px;
    }

    img {
        width: 30px;
        height: 30px;
        overflow: hidden;
        object-fit: cover;
    }

    p {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }

    &:hover {
        border: 1px solid var(--primary-color);
    }
}

.disabledBlock {
    pointer-events: none;
    opacity: 0.2;
}
.blockSmall {
    background: #222837;
    border: 1px solid transparent;
    border-radius: 12px;
    padding-top: 23px;
    padding-bottom: 23px;
    width: 100%;
    max-width: 106px;
    gap: 0;
    justify-content: space-between;
    height: 106px;
    margin-bottom: 2px;
    div {
        width: 32px;
        height: 32px;
    }
    svg {
        width: 16px;
        height: 16px;
    }
    img {
        width: 16px;
        height: 16px;
    }
    p {
        font-weight: 400;
        line-height: 15px;
        font-size: 12px;
    }
}
@media screen and (max-width: 576px) {
    .blockWrpap {
        display: block;
        position: relative;
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }
        .blockSmall {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            padding-top: 0px;
            padding-bottom: 0px;
            justify-content: center;
            div {
                margin-bottom: 10px;
            }
        }
    }
    .notAbsolute{
        &::after{
            content: none;
        }
    }
}
.blockMedium {
    background: #222837;
    border: 0.8px solid #6b6b6b;
    border-radius: 6.4px;
    border-radius: 12px;
    padding-top: 23px;
    padding-bottom: 23px;
    width: 100%;
    max-width: 116.8px;
    gap: 0;
    justify-content: space-between;
    height: 116.8px;
    margin-bottom: 2px;
    div {
        width: 46.08px;
        height: 46.08px;
    }
    svg {
        width: 23.04px;
        height: 23.04px;
    }
    img {
        width: 23.04px;
        height: 23.04px;
    }
    p {
        font-weight: 400;
        line-height: 15px;
        font-size: 12px;
    }
}
@media screen and (max-width: 576px) {
    .blockWrpap {
        display: block;
        position: relative;
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }
        .blockMedium {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            padding-top: 0px;
            padding-bottom: 0px;
            justify-content: center;
            div {
                margin-bottom: 10px;
            }
        }
        div {
            width: 32px;
            height: 32px;
        }
        svg {
            width: 16px;
            height: 16px;
        }
        img {
            width: 16px;
            height: 16px;
        }
    }
    .notAbsolute{
        &::after{
            content: none;
        }
    }
}
