.wrapper {
    top: var(--header-height);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: var(--z-index-mobile-menu);
    background: var(--background);
    padding-top: 20px;
    max-width: 100vw;
    overflow-y: auto;
    padding-bottom: 100px;
}

.container {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 var(--container-padding);
}