.wrapper {
    height: var(--header-height);
    background-color: #282b31;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px
}

.container {
    width: 100%;
    max-width: 1504px;
    padding: 0 var(--container-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
}

.rightSide {
    display: flex;
    gap: 40px;
    height: 100%;
    align-items: center;
}

.buttonMobileMenu {
    display: none;
}

.nav {
    display: flex;
    gap: 40px;
    align-items: center;
    height: 100%;

    &__item {
        font-weight: 400;
        color: #FFFFFF;
        font-size: 14px;
        text-transform: uppercase;
        transition: color var(--animation-transition-speed);
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
            color: var(--primary-color);
            transition: color var(--animation-transition-speed);

            div {
                opacity: 1;
                transition: all var(--animation-transition-speed);
                pointer-events: inherit;
            }

            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }

        div {
            display: flex;
            position: absolute;
            left: 0;
            top: var(--header-height);
            flex-flow: column nowrap;
            background: #222837;
            padding: 12px 20px;
            z-index: var(--z-index-header-nav-menu);
            gap: 16px;
            width: 225px;
            opacity: 0;
            transition: all var(--animation-transition-speed);
            pointer-events: none;

            a {
                font-size: 14px;
                color: #D9D9D9;
                font-weight: 400;
                text-align: center;
                padding-bottom: 12px;
                border-bottom: 2px solid #222837;

                &:hover {
                    border-bottom: 2px solid #2261F6;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                path {
                    fill: #FFFFFF;
                }
            }
        }
    }
}

.loginButton {
    padding: 8px 40px;
    border-radius: 16px;
    background: var(--primary-color);
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.userInfoPopup {
    position: absolute;
    right: 0;
    top: calc(var(--header-height) + 8px);
    padding: 9px 9px 17px 9px;
    background: #222837;
    border-radius: 12px;
    width: 356px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    gap: 16px;

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        color: #D9D9D9;
    }
}

@media screen and (max-width: 992px) {
    .rightSide {
        gap: 20px;
    }

    .buttonMobileMenu {
        display: flex;
        align-items: center;
        width: 20px;
    }

    .logo {
        img {
            height: 32px;
        }
    }

    .nav,
    .user,
    .loginButton {
        display: none;
    }
}