.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 77px var(--container-padding);
}

.container {
    width: 100%;
    max-width: 433px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.logo {
    width: 132px;
    margin-bottom: 150px;

    img {
        width: 100%;
    }
}

.title {
    font-weight: 700;
    font-size: 64px;
    color: #EEEEEE;
    margin-bottom: 28px;
    text-transform: uppercase;
    line-height: 64px;
    text-align: left;
    width: 100%;
}

.text {
    font-size: 24px;
    color: #D9D9D9;
    margin-bottom: 28px;
}

.button {
    margin-bottom: 110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 4px;
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    height: 44px;
    align-self: center;
    margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
    .title {
        font-size: 40px;
        line-height: 40px;
    }

    .logo {
        margin-bottom: 130px;
    }

    .text {
        font-size: 16px;
    }

    .button {
        font-size: 14px;
    }
}