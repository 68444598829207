@mixin line {
    height: 16px;
    width: 100%;
    border-radius: 4px;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        inset-inline-end: -150%;
        bottom: 0;
        inset-inline-start: -150%;
        background: linear-gradient(
            90deg,
            rgba(40, 43, 49, 0.2) 25%,
            rgba(40, 43, 49, 0.6) 37%,
            rgba(40, 43, 49, 0.2) 63%
        );
        animation-name: loadingAnimation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        content: '';
    }
}

.wrapper {
    width: 100%;
}

.paragraph {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;

    div {
        @include line;
    }
}

.title {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;

    div {
        @include line;
    }
}

@keyframes loadingAnimation {
    0% {
        transform: translateX(-37.5%);
    }

    100% {
        transform: translateX(37.5%);
    }
}
