.primary {
    background: var(--primary-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    height: 44px;
    font-size: 16px;
    font-weight: 400;
}


.disabled {
    background: rgba(229, 229, 229, 0.2);
    color: #8E8E8E;
    pointer-events: none;
}