.policy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    p,
    a {
        font-weight: 400;
        font-size: 16px;
        color: #D9D9D9;
    }

    a {
        text-decoration: underline;
    }
}