.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
    gap: 24px;
    background: rgba(30, 35, 45, 0.5);
    border-radius: 12px;
}

.header {
    display: flex;
    justify-content: space-between;

    &Currency {
        display: flex;
        gap: 8px;
        align-items: center;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
        }

        div {
            display: flex;
            flex-flow: column nowrap;
            gap: 8px;

            span {
                &:first-child {
                    color: #fff;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 600;
                    font-family: 'Rubik';
                    text-transform: uppercase;
                }

                &:last-child {
                    color: #8a929a;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    font-family: 'Rubik';
                }
            }
        }
    }

    &Info {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        gap: 8px;

        span {
            &:first-child {
                color: #fff;
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
                font-family: 'Rubik';
                text-transform: uppercase;
            }

            &:last-child {
                color: #8a929a;
                font-size: 12px;
                line-height: 12px;
                font-weight: 400;
                font-family: 'Rubik';
            }
        }
    }
}

.footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &Info {
        display: flex;
        flex-flow: row nowrap;
        gap: 12px;

        div {
            display: flex;
            flex-flow: column nowrap;
            gap: 4px;

            span {
                &:first-child {
                    color: #8a929a;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    font-family: 'Rubik';
                }

                &:last-child {
                    color: #80ff77;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: 'Rubik';
                }
            }

            &:last-child span:last-child {
                color: #ffe600;
            }
        }
    }

    &Actions {
        position: relative;
        display: flex;
        gap: 8px;
    }
}

@media screen and (max-width: 576px) {
    .footer {
        flex-flow: column nowrap;
        gap: 20px;
    }
    .headerInfo {
        span {
            &:first-child {
                font-size: 14px;
                text-align: right;
            }
        }
    }
    .ticker {
        font-size: 16px !important;
    }
}
.ticker {
    margin-right: 8px;
}
