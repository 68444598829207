.nav {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 24px;

    &__label {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        padding-bottom: 8px;
        border-bottom: 1px dashed #C6C6C6;
        font-size: 14px;
        font-weight: 600;
        color: var(--primary-color);
        width: 100%;

        svg {
            transition: transform var(--animation-transition-speed);

            path {
                fill: var(--primary-color);
            }
        }
    }

    &__list {
        display: flex;
        flex-flow: column nowrap;
        gap: 28px;
        overflow: hidden;
        width: 100%;

        a {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
            color: #D9D9D9;
        }
    }
}

.labelActive {
    svg {
        transform: rotate(180deg);
        transition: transform var(--animation-transition-speed);
    }
}