.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 44px;
}

.container {
    width: 100%;
    max-width: calc(590px + var(--container-padding) * 2);
    display: flex;
    flex-flow: column nowrap;
    gap: 32px;
    padding: 0 var(--container-padding);
}

.user {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
    width: 100%;

    &__image {
        width: 122px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #444444;
        border-radius: 12px;
    }

    &__info {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        &__id {
            height: 32px;
            padding: 0 40px;
            font-weight: 600;
            font-size: 14px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            background: var(--primary-color);
            border-radius: 16px;
            margin-bottom: 12px;
        }

        &__email {
            font-size: 20px;
            color: var(--font-base-color);
            font-weight: 400;
        }
    }
}

.options {
    display: flex;
    gap: 20px;
}

.businessAccount,
.identyVerification {
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
    border-radius: 12px;
    width: 50%;
    align-items: flex-start;

    span {
        font-size: 18px;
        font-weight: 600;
    }

    div {
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 400;
        color: #171d22;
        height: 32px;
        padding: 0 44px;
        margin-top: auto;
    }
}

.businessAccount {
    background: #333c52;
    gap: 34px;

    span {
        color: var(--primary-color);
    }
}

.identyVerification {
    background: #494e5a;
    gap: 24px;

    span {
        color: #fff;
    }

    svg path {
        fill: #fff;
    }
}

.list {
    gap: 32px;
    display: flex;
    flex-flow: column nowrap;
}

@media screen and (max-width: 992px) {
    .user {
        &__image {
            width: 80px;
            height: 80px;
        }

        &__info {
            &__email {
                font-size: 16px;
            }
        }
    }

    .options {
        flex-flow: column nowrap;
    }

    .businessAccount,
    .identyVerification {
        width: 100%;
    }
}
