.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: calc(440px + (var(--container-padding) * 2));
    padding: 0 var(--container-padding);
    position: relative;
}

@media screen and (max-width: 992px) {
    .wrapper {
        padding-top: 40px;
    }
}