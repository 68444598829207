.wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    width: 100%;
}

.input {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    color: var(--font-base-color);
    border: 1px solid #CED4DA;
    outline: none;
    background: none;

    &::placeholder {
        color: #D9D9D9;
    }
}

.error {
    background: rgba(255, 1, 1, 0.2);
    border-radius: 4px;
    padding: 13px 17px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;

    &::first-letter {
        text-transform: uppercase;
    }
}

@media screen and (max-width: 992px) {
    .error {
        font-size: 14px;
    }

    .input {
        font-size: 14px;
    }
}