.wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}

.links {
    display: flex;
    flex-flow: row nowrap;
    gap: 14px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    a {
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        width: 100%;
        height: 35px;
        text-transform: uppercase;
    }
}

.supportLink {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.sign {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
