.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    margin-top: 16px;
}

.submitButton {
    width: 100%;
    max-width: 244px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 4px;
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    height: 44px;
    align-self: center;
}

.actions {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;

    button {
        flex: 1 1;
        border-radius: 4px;
        height: 44px;
        font-size: 16px;
        font-weight: 400;
    }
}

.backButton {
    background: #8E8E8E;
    color: #fff;
}

.nextButton {
    background: var(--primary-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.colorWhite{
    color:#ffffff;
}

@media screen and (max-width: 992px) {
    .actions {
    
        button {
            font-size: 14px;
        }
    }
}