.wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px var(--container-side-padding) 20px var(--container-side-padding);
}

.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    gap: 20px;
    
}