.wrapper {
  //display: flex;
  //flex-flow: column nowrap;
  gap: 6px;
  width: 100%;

  label {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #8a929a;
  }
}

.input {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 340px;
  min-height: 340px;
  max-height: 340px;
  background: none;
  border-radius: 8px;
  padding-left: 16px;
  padding-top: 16px;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #55afcb;
  outline: none;
  resize: none;
  color: #8a929a;

  &::placeholder {
    color: #fff;
  }
}

@media screen and (max-width: 992px) {
  .input {
    font-size: 14px;
  }
}
