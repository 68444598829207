.nav {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 24px;

    &__label {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);

        svg {
            path {
                fill: var(--primary-color);
            }
        }
    }

    &__list {
        display: flex;
        flex-flow: column nowrap;
        gap: 28px;

        a {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
            color: #d9d9d9;
        }
    }
}