.tabs {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;

    a {
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(229, 229, 229, 0.2);
        border-radius: 4px;

        a:not(.active) {
            color: #fff;
            background: rgba(229, 229, 229, 0.2);
        }
    }

    .active {
        background-color: var(--primary-color);
        color: #fff;
        pointer-events: none;
    }
}

@media screen and (max-width: 992px) {
    .tabs {
        a {
            font-size: 14px;
        }
    }
}