.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.label {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}