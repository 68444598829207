.App {
    background-color: var(--background);
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}

.App-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}


.main-slider {
    margin-bottom: 16.44px;
}

.main-slider .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.8);
}

.main-slider .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 37px;
}

.main-slider .swiper {
    border-radius: 12px;
}

.main-slider .react-multi-carousel-list {
    border-radius: 12px !important;
}

.main-slider .react-multi-carousel-dot-list {
    bottom: 35px !important;
}

.main-slider .react-multi-carousel-dot button {
    width: 7px !important;
    height: 7px !important;
    border: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
}

.main-slider .react-multi-carousel-dot--active button {
    background-color: #fff !important;
}

/* .border-w-116{
    background: #1B1E26;
    border: 0.8px solid #6B6B6B;
    border-radius: 6.4px;
    max-width: 116px;
    height: 116px;
} */
@media screen and (max-width: 576px) {
    .main-slider {
        margin-top: -60px;
    }
}

.loader-center {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.react-datepicker {*/
/*    background-color: transparent;*/
/*    color: #fff;*/
/*    border: none;*/
/*}*/

/*.react-datepicker__header {*/
/*    background-color: transparent;*/
/*    border: none;*/
/*}*/

/*.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {*/
/*    color: #fff;*/
/*}*/

/*.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {*/
/*    color: #fff;*/
/*    font-family: 'Inter';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    line-height: 19px;*/
/*}*/

/*.react-datepicker__day--outside-month {*/
/*    color: #AAAAAA;*/
/*}*/

/*.react-datepicker__day--keyboard-selected {*/
/*    background: #2261F6;*/
/*}*/

/*.react-datepicker__day--selected {*/
/*    background: #2261F6;*/
/*}*/

/*.react-datepicker__day:hover {*/
/*    background: #2261F6;*/
/*}*/

/*.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {*/
/*    width: 39px;*/
/*    height: 37px;*/
/*    line-height: 37px;*/
/*    font-family: 'Inter';*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    font-size: 16px;*/
/*}*/

/*.react-datepicker__day-name {*/
/*    font-family: 'Inter';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 16px;*/
/*}*/

/*@media screen and (max-width: 350px) {*/
/*    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {*/
/*        width: 34px;*/
/*        height: 32px;*/
/*        line-height: 32px;*/
/*    }*/
/*}*/

/*.react-datepicker__navigation-icon::before {*/
/*    border-color: #fff;*/
/*}*/

/*.react-datepicker__navigation-icon--next::after {*/
/*    border-color: #fff;*/
/*    border-style: solid;*/
/*    border-width: 3px 3px 0 0;*/
/*    content: "";*/
/*    display: block;*/
/*    height: 9px;*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    width: 9px;*/
/*    transform: rotate(45deg);*/
/*    left: -14px;*/
/*}*/

/*.react-datepicker__navigation-icon--previous::after {*/
/*    border-color: #fff;*/
/*    border-style: solid;*/
/*    border-width: 3px 3px 0 0;*/
/*    content: "";*/
/*    display: block;*/
/*    height: 9px;*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    width: 9px;*/
/*    transform: rotate(225deg);*/
/*    right: -14px;*/
/*}*/

/*.react-datepicker__navigation:hover *::before {*/
/*    border-color: #fff;*/
/*}*/