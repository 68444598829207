.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 8px var(--container-side-padding) 20px
        var(--container-side-padding);
    background: rgba(0, 0, 0, 0.35);
    z-index: var(--z-index-modals);
    overflow: auto;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--container-width);
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 12px;
    margin-top: 10px;
    overflow: hidden;
}

@media screen and (max-width: 576px) {
    .wrapper{
        position: fixed;
        top: 100%;
        left: 0;
        width: 100%;
        // background: #12161e;
        z-index: 1000;
        height: 100vh;
        overflow-y: auto;
        padding: 0;
        align-items: flex-start;
    }
    .container {
        margin-top: 0;
        border-radius: 0;
        background: initial;
    }
}
