.text {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #8a929a;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 -20px;
    flex: 1;
    background: rgba(0, 0, 0, 0.9);
}
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
}
.actions {
    display: flex;
    gap: 16px;
    padding: 24px 20px;
    margin-top: auto;
    background: #141414;
    width: 100%;

    button {
        text-transform: none;
    }
}
