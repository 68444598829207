.wrapper {
    width: 100%;
    background: rgba(175, 175, 175, 0.2);
    border-radius: 12px;
    display: flex;
    flex-flow: row nowrap;
    padding-right: 15px;
    padding-left: 10px;
    height: 66px;
    align-items: center;
}

.avatar {
    height: 51px;
    width: 51px;
    overflow: hidden;
    margin-right: 6px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.info {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;

    &__header {
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;
        align-items: center;
    }

    &__email {
        color: #D9D9D9;
        font-size: 14px;
        font-weight: 400;
    }
}

.userID {
    background: var(--primary-color);
    border-radius: 9.5px;
    color: #000000;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    max-width: 86px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.userProAccount {
    svg {
        path {
            fill: #686868
        }
    }
}

.rightSide {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.logoutButton {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        path {
            fill: #FFFFFF;
        }
    }
}