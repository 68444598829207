.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 28px;
}

.container {
    width: 100%;
    max-width: 588px;
    display: flex;
    flex-flow: column nowrap;

    h1 {
        font-size: 32px;
        color: var(--font-base-color);
        margin-bottom: 32px;
        font-weight: 600;
        text-align: center;
    }
}

.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    align-items: center;

    textarea {
        width: 100%;
        background: none;
        min-height: 310px;
        max-height: 310px;
        max-width: 100%;
        min-width: 100%;
        border-radius: 4px;
        padding-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--font-base-color);
        border: 1px solid #CED4DA;
        outline: none;
        resize: none;

        &::placeholder {
            color: #727272;
        }
    }

    button {
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        border-radius: 4px;
        padding: 0 60px;
        height: 44px;
    }
}