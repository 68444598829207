.wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.authLinks {
    display: flex;
    gap: 20px;

    a {
        flex-grow: 1;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;

        &:first-child {
            background-color: var(--primary-color);
            color: #000;
        }

        &:last-child {
            background: rgba(229, 229, 229, 0.2);
            color: #8E8E8E
        }
    }
}


.form {
    width: calc(100% + var(--container-padding) * 2);
    left: calc(var(--container-padding) * -1);
    position: relative;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 40px 40px;
    border-bottom: 2px solid #404040;

    &__label {
        color: #D9D9D9;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 12px;
        margin-top: 24px;
        text-transform: uppercase;
    }

    &__socialNetworks {
        display: flex;
        gap: 30px;
    }
}

.container {
    padding-bottom: 35px;
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.buttonClose {
    background: #858585;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: 16px;

    svg {
        width: 14px;

        path {
            fill: #343C4D
        }
    }
}


.disableEvent {
    pointer-events: none;
    z-index: -1;
}
