.wrapper {
    position: relative;
    height: 16px;
    width: 16px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;

        &:checked~img {
            display: block;
        }

        &:checked~span {
            background: #919191;
        }
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border: 1px solid #919191;
        border-radius: 2px;
    }

    img {
        position: absolute;
        display: none;
        z-index: 1;
        left: 50%;
        top: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }
}